<template>
  <slot name="actions" />
  <div class="bubble relative transform md:transform-none -translate-x-1/2">
    <slot />
  </div>
</template>
<script setup lang="ts"></script>
<style lang="postcss" scoped>
.bubble {
  content: '';
  position: fixed;
  clip-path: circle(50%);
  width: 1100px;
  height: 1100px;
  background: rgb(255, 255, 255);
  z-index: -1;
  left: 50%;
  top: 200px;
}

@screen md {
  .bubble {
    /** reset all the values */
    content: none;
    position: static;
    clip-path: none;
    width: 100%;
    height: 100%;
    background: none;
    z-index: auto;
    left: auto;
    transform: none;
    top: auto;
  }
}
</style>
